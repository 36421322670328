import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from "../colors/colors"

export const HalfCard = styled(Link)`
  width: 50%;
  height: 300px;
  padding: 0;
  margin: 0;
  background: ${colors.black};
  flex-shrink: 0;
  color: ${colors.white};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 50px;

  :nth-child(3n) {
    width: 100%;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`
export const CardImage = styled(Img)`
  grid-area: 1/1/3/3;
  object-fit: cover;
`

export const CardTitle = styled.h2`
  padding-left: 35px;
  padding-bottom: 35px;
  align-self: center;
  grid-area: 2/1/3/3;
  z-index: 99;
  font-size: 30px;
  line-height: 1.2;

  @media (max-width: 815px) {
    grid-area: 2/1/3/3;
  }
`
const SmallProjectCard = ({ slug, title, src }) => {
  return (
    <HalfCard to={slug} key={slug}>
      <CardImage fluid={src} />
      <CardTitle>{title}</CardTitle>
    </HalfCard>
  )
}

export default SmallProjectCard
