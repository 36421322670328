import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { colors } from "../components/colors/colors"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MainHeader from "../components/header-components/mainHeader"
import SmallProjectCard from "../components/cards/smallProjectCards"

const ProjectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.black};

  @media (max-width: 1000px) {
    width: 100%;
    height: 100%;
  }
`

const ProjectSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: wrap row;
  background: ${colors.black};

  @media (max-width: 1000px) {
    flex-flow: wrap column;
  }

  @media (max-width: 811px) {
    margin-top: 20px;
  }
`

class ProjectPage extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allSanityPost.edges
    return (
      <Layout>
        <SEO title="Projects" />
        <ProjectContainer>
          <MainHeader />
          <ProjectSection>
            {posts.map(({ node }, i) => {
              const title = node.title
              const src = node.featuredImage.asset.fluid
              return (
                <SmallProjectCard
                  title={title}
                  slug={`${node.slug.current}`}
                  key={title}
                  src={src}
                />
              )
            })}
          </ProjectSection>
        </ProjectContainer>
      </Layout>
    )
  }
}

export default ProjectPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allSanityPost(
      sort: { order: ASC, fields: order }
      filter: { active: { eq: true } }
    ) {
      edges {
        node {
          slug {
            current
          }
          featuredImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
        }
      }
    }
  }
`
